import Vue from 'vue';
import i18n from './i18n';
import App from './App.vue';

Vue.config.productionTip = false;

let app;

function init() {
  if (!document.getElementById('auth') || (app && document.body.contains(app.$el))) {
    return;
  }

  app = new Vue({
    render: (h) => h(App),
    i18n,
  }).$mount('#auth');
}

init();

setInterval(init, 500);