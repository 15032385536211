<template>
  <div id="auth">
    <template v-if="tab === 'login'">
      <h2 class="h2">{{ $t("login") }}</h2>
      <v-input placeholder="Email" v-model="email" name="email" />
      <v-input
        :placeholder="$t('password')"
        name="password"
        v-model="login.password"
        native-type="password"
      />
      <v-error :text="login.error"></v-error>
      <v-success :text="login.success"></v-success>
      <v-button @click.prevent="tryLogin" :pending="pending">{{
        $t("signIn")
      }}</v-button>
      <p class="hint">
        {{ $t("ifYouDontHaveAccount") }}&nbsp;<button
          class="hint__button"
          @click.stop="tab = 'reg'"
        >
          {{ $t("toRegister") }}
        </button>
      </p>
      <p class="hint">
        <button
          class="hint__button"
          @click.stop="tab = 'restorePassword'"
        >
        {{ $t("restorePassword") }}
        </button>
      </p>
    </template>

    <template v-else-if="tab === 'confirmLogin'">
      <h2 class="h2">{{ $t("enter2FACode") }}</h2>
      <v-input
        :placeholder="$t('code')"
        ref="confirmLoginCodeInput"
        v-model="login.code"
      ></v-input>
      <v-error :text="login.confirmError"></v-error>
      <v-button @click.stop="confirmLogin" :pending="pending">{{
        $t("send")
      }}</v-button>
      <p class="hint">
        <button
          class="hint__button"
          @click.prevent="
            tab = 'login';
            login.confirmError = '';
          "
        >
          {{ $t("backToLogin") }}
        </button>
      </p>
    </template>

    <template v-else-if="tab === 'reg'">
      <h2 class="h2">{{ $t("register") }}</h2>
      <v-input placeholder="Email" name="email" v-model="email" />
      <v-input
        :placeholder="$t('phone')"
        type="phone"
        name="phone"
        v-model="reg.phone"
      />
      <v-radio
        :label="$t('userType')"
        v-model="reg.userType"
        :options="[
          { label: $t('personal'), value: 1 },
          { label: $t('business'), value: 2 },
        ]"
      />
      <v-input
        v-if="reg.userType === 2"
        :placeholder="$t('companyName')"
        v-model="reg.companyName"
      ></v-input>
      <v-error :text="reg.error"></v-error>
      <v-button @click="tryReg" :pending="pending">{{ $t("signUp") }}</v-button>
      <p class="hint">
        {{ $t("alreadyHaveAccount") }}&nbsp;<button
          class="hint__button"
          @click.prevent="tab = 'login'"
        >
          {{ $t("toLogin") }}
        </button>
      </p>
    </template>

    <template v-else-if="tab === 'confirmReg'">
      <h2 class="h2">{{ $t("enterConfirmationCode") }}</h2>
      <v-input
        :placeholder="$t('code')"
        ref="confirmRegCodeInput"
        v-model="reg.code"
      />
      <v-error :text="reg.confirmError"></v-error>
      <v-button @click="confirmReg" :pending="pending">{{
        $t("send")
      }}</v-button>
      <p class="hint">
        <button
          class="hint__button"
          @click.prevent="
            tab = 'reg';
            reg.confirmError = '';
          "
        >
          {{ $t("backToRegistration") }}
        </button>
      </p>
    </template>

    <template v-else-if="tab === 'restorePassword'">
      <h2 class="h2">{{ $t("restorePasswordTitle") }}</h2>
      <v-input
        placeholder="Email"
        v-model="restore.email"
      />
      <v-error :text="restore.error"></v-error>
      <v-button @click="restorePassword" :pending="pending">{{
        $t("send")
      }}</v-button>
      <p class="hint">
        <button
          class="hint__button"
          @click.stop="tab = 'login'"
        >
          Login
        </button>
      </p>
    </template>

    <template v-else-if="tab === 'restorePassword2'">
      <h2 class="h2">{{ $t("restorePasswordTitle") }}</h2>
      <div class="form-text">
        Request submitted, please check your email.
      </div>
    </template>

    <template v-else-if="tab === 'restorePasswordCode'">
      <h2 class="h2">{{ $t("restorePasswordTitle") }}</h2>
      <v-input
        :placeholder="$t('newPassword')"
        v-model="restore.password"
      />
      <v-error :text="restore.error"></v-error>
      <v-button @click="restorePasswordCode" :pending="pending">{{
        $t("send")
      }}</v-button>
      <p class="hint">
        <button
          class="hint__button"
          @click.stop="tab = 'login'"
        >
          Login
        </button>
      </p>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import VInput from './components/VInput.vue';
import VButton from './components/VButton.vue';
import VRadio from './components/VRadio.vue';
import VError from './components/VError.vue';
import VSuccess from './components/VSuccess.vue';

const $api = axios.create({
  baseURL: 'https://cabinet.crederes.com/api/web-api/',
});

export default {
  components: {
    VInput, VButton, VRadio, VError, VSuccess,
  },
  data() {
    return {
      tab: 'login',
      pending: false,
      email: '',
      login: {
        password: '',
        error: '',
        success: '',
        confirmError: '',
        code: '',
      },
      reg: {
        companyName: '',
        phone: '',
        userType: 1,
        error: '',
        confirmError: '',
        code: '',
      },
      user: {
        role: null,
        id: null,
        token: null,
      },
      restore: {
        email: '',
        error: '',
        password: '',
        token: ''
      }
    };
  },
  mounted() {
    if (window.location.href.indexOf('forgot-password-finish') > -1) {
      const urlParams = new URLSearchParams(window.location.search)
      const email = urlParams.get('email')
      const token = urlParams.get('token')
      if (email) {
        this.restore.email = email
        this.restore.token = token
        this.tab = 'restorePasswordCode'
      }
    }
  },
  methods: {
    tryLogin() {
      this.pending = true;
      $api.post('AccountService/Login', {
        Email: this.email,
        Password: this.login.password,
      }).then(({ data }) => {
        if (!data.IsSuccess) {
          this.login.error = this.getError(data.ErrorCode);
          this.pending = false;
          return;
        }

        this.login.error = '';

        this.user.id = data.Value.Id;
        this.user.role = data.Value.Role;

        if (data.Value.IsTwoFactorAuthentication) {
          this.tab = 'confirmLogin';
          this.pending = false;
          this.$nextTick(() => {
            this.$refs.confirmLoginCodeInput.$el.querySelector('input').focus();
          });
          return;
        }

        this.user.token = data.Value.SessionToken;

        this.redirect();
      }).catch(() => {
        this.login.error = this.$t('somethingWentWrong');
        this.pending = false;
      });
    },
    confirmLogin() {
      if (!this.login.code) {
        this.login.confirmError = this.$t('enterCodeError');
        return;
      }

      this.pending = true;
      $api.post('AccountService/ConfrimCode', {
        Code: this.login.code,
        UserId: this.user.id,
      }).then(({ data }) => {
        if (!data.IsSuccess) {
          this.login.confirmError = this.getError(data.ErrorCode);
          this.pending = false;
          return;
        }

        this.user.token = data.Value.Token;
        this.redirect();
      }).catch(() => {
        this.login.confirmError = this.$t('somethingWentWrong');
        this.pending = false;
      });
    },
    redirect() {
      // eslint-disable-next-line no-restricted-globals
      location.href = `https://cabinet.crederes.com/${this.$i18n.locale}/${this.user.role}/Auth/LoginByToken?token=${this.user.token}`;
    },
    tryReg() {
      this.pending = true;
      $api.post('AccountService/RegisterStart', {
        Email: this.email,
        TypeUser: this.reg.userType,
        PhoneNumber: this.reg.phone,
      }).then(({ data }) => {
        if (!data.IsSuccess) {
          this.reg.error = this.getError(data.ErrorCode);
          return;
        }

        this.reg.error = '';

        this.tab = 'confirmReg';
        this.$nextTick(() => {
          this.$refs.confirmRegCodeInput.$el.querySelector('input').focus();
        });
      }).catch(() => {
        this.reg.error = this.$t('somethingWentWrong');
      }).finally(() => {
        this.pending = false;
      });
    },
    confirmReg() {
      if (!this.reg.code) {
        this.reg.confirmError = this.$t('enterCodeError');
        return;
      }

      this.pending = true;
      $api.post('AccountService/RegisterEnd', {
        Email: this.email,
        TypeUser: this.reg.userType,
        PhoneNumber: this.reg.phone,
        CompanyName: this.reg.companyName,
        PinCode: this.reg.code,
      }).then(({ data }) => {
        if (!data.IsSuccess) {
          this.reg.confirmError = this.getError(data.ErrorCode);
          return;
        }

        this.reg.confirmError = '';
        this.login.success = this.$t('youSignedUp');
        this.login.error = '';
        this.tab = 'login';
      }).catch(() => {
        this.reg.confirmError = this.$t('somethingWentWrong');
      }).finally(() => {
        this.pending = false;
      });
    },
    getError(errorCode) {
      const key = `ERROR_${errorCode}`;
      if (this.$te(key)) {
        return this.$t(key);
      }

      return this.$t('somethingWentWrong');
    },
    restorePassword() {
      if(!this.restore.email) {
        this.restore.error = this.$t('ERROR_4')
        return
      }
      this.pending = true
      this.restore.error = ''
      $api.post('AccountService/RecoverPasswordRequest', this.restore.email, { headers: { "Content-Type": "text/plain" }})
        .then(({ data }) => {
          if (!data.IsSuccess) {
            this.restore.error = this.getError(data.ErrorCode);
            return;
          }
        
        this.tab = 'restorePassword2'
      }).catch(() => {
        this.restore.error = this.$t('somethingWentWrong');
      }).finally(() => {
        this.pending = false;
      });
    },
    restorePasswordCode() {
      this.pending = true
      this.restore.error = ''
      $api.post('AccountService/RecoverPassword', {
        Email: this.restore.email,
        Token: this.restore.token,
        Password: this.restore.password
      }).then(({ data }) => {
          if (!data.IsSuccess) {
            this.restore.error = this.getError(data.ErrorCode);
            return;
          }
        
        this.tab = 'login'
      }).catch(() => {
        this.restore.error = this.$t('somethingWentWrong');
      }).finally(() => {
        this.pending = false;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
#auth {
  padding: 20px;
}

#auth::v-deep {
  * {
    font-family: "FuturaPT", Arial, sans-serif;
    box-sizing: border-box;
  }
}
.h2 {
  font-weight: 600;
  color: #121212;
  margin-bottom: 24px;
  font-size: 36px;
  line-height: 1.23;
  text-align: center;
}
.hint {
  margin-top: 20px;
  text-align: center;
  font-size: 15px;
  line-height: 1.55;
  font-weight: 300;
  color: #242424;

  &__button {
    display: inline;
    color: #ff8562;
    text-decoration: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    font-size: 15px;
    line-height: 1.55;

    &-block {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.form-text {
  text-align: center;
}
</style>
