import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const i18n = new VueI18n({
  // eslint-disable-next-line no-underscore-dangle
  locale: window.__LOCALE__ || 'en',
  messages: {
    en: {
      login: 'Login',
      password: 'Password',
      newPassword: 'New password',
      somethingWentWrong: 'Something went wrong',
      signIn: 'Sign in',
      youSignedUp: 'You\'ve successfuly signed up. Confirmation link was sent to your email',
      ifYouDontHaveAccount: 'If you don`t have account, please',
      toRegister: 'register',
      enter2FACode: 'Please enter 2FA code',
      code: 'Code',
      send: 'Send',
      backToLogin: 'Back to login',
      register: 'Register',
      phone: 'Phone',
      userType: 'Account type',
      personal: 'Personal',
      business: 'Business',
      companyName: 'Company name',
      signUp: 'Sign up',
      alreadyHaveAccount: 'Already have account? Please,',
      toLogin: 'login',
      enterConfirmationCode: 'Please enter confirmation code',
      backToRegistration: 'Back to registration',
      enterCodeError: 'Please, enter code',
      ERROR_1: 'Internal error',
      ERROR_2: 'Incorrect code',
      ERROR_3: 'User is not found',
      ERROR_4: 'Email can not be empty',
      ERROR_5: 'User with this email address already exists',
      ERROR_6: 'Failed to register',
      ERROR_7: 'User registration data is not found',
      ERROR_8: 'Wrong login or password',
      ERROR_9: 'Authorization attempts limit exceeded',
      ERROR_10: 'Invalid password',
      ERROR_11: 'Wrong token',
      ERROR_12: 'User with this phone already exists',
      ERROR_13: 'Failed to send SMS',
      restorePassword: 'Forgot password',
      restorePasswordTitle: 'Restore password',
      confirmationCode: 'Confirmation code'
    },
    ru: {
      login: 'Авторизация',
      password: 'Пароль',
      newPassword: 'Новый пароль',
      somethingWentWrong: 'Что-то пошло не так',
      signIn: 'Войти',
      youSignedUp: 'Вы успешно зарегистрировались. На указанный Вами e-mail отправлено письмо с запросом подтверждения регистрации',
      ifYouDontHaveAccount: 'Если у вас нет аккаунта, пожалуйста,',
      toRegister: 'зарегистрируйтесь',
      enter2FACode: 'Введите код двухфакторной аутентификации',
      code: 'Код',
      send: 'Отправить',
      backToLogin: 'Обратно к авторизации',
      register: 'Регистрация',
      phone: 'Телефон',
      userType: 'Тип аккаунта',
      personal: 'Личный',
      business: 'Деловой',
      companyName: 'Название компании',
      signUp: 'Зарегистрироваться',
      alreadyHaveAccount: 'Уже есть аккаунт?',
      toLogin: 'Войдите',
      enterConfirmationCode: 'Введите код подтверждения',
      backToRegistration: 'Обратно к регистрации',
      enterCodeError: 'Пожалуйста, введите код',
      ERROR_1: 'Внутренняя ошибка',
      ERROR_2: 'Неверный код',
      ERROR_3: 'Пользователь не найден',
      ERROR_4: 'Email не может быть пустым',
      ERROR_5: 'Пользователь с такой почтой существует',
      ERROR_6: 'Не удалось зарегистрироваться',
      ERROR_7: 'Данные регистрации пользователя не найдены',
      ERROR_8: 'Неверный логин или пароль',
      ERROR_9: 'Превышен лимит попыток авторизации',
      ERROR_10: 'Пароль должен содержать одну заглавную букву, одну цифру и длина пароля не менее 8 символов',
      ERROR_11: 'Неверный токен',
      ERROR_12: 'Пользователь с таким телефоном уже существует',
      ERROR_13: 'Не удалось отправить сообщение',
      restorePassword: 'Не помню пароль',
      restorePasswordTitle: 'Восстановить пароль',
      confirmationCode: 'Код подтверждения'
    },
  },
});

export default i18n;
