var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "button",
      class: { button_pending: _vm.pending },
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.onClick.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "button__loader" }),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }