<template>
  <div class="error" v-show="text">
    {{ text }}
  </div>
</template>

<script>
export default {
  props: ['text'],
};
</script>

<style lang="scss" scoped>
.error {
  background: #f95d51;
  padding: 20px 10px;
  text-align: center;
  margin-bottom: 20px;
  color: #fff;
  font-size: 20px;
  font-weight: 300;
}
</style>
