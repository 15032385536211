var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "radio-wrapper" },
    [
      _c("span", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))]),
      _vm._v(" "),
      _vm._l(_vm.options, function (option) {
        return _c("label", { key: option.value, staticClass: "option" }, [
          _c("input", {
            attrs: { type: "radio", name: _vm.name },
            domProps: {
              value: option.value,
              checked: _vm.value === option.value,
            },
            on: {
              change: function ($event) {
                return _vm.$emit("input", option.value)
              },
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "option__indicator" }),
          _vm._v("\n    " + _vm._s(option.label) + "\n  "),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }