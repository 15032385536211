<template>
  <div class="radio-wrapper">
    <span class="label">{{ label }}</span>
    <label class="option" v-for="option in options" :key="option.value">
      <input
        type="radio"
        :name="name"
        :value="option.value"
        :checked="value === option.value"
        @change="$emit('input', option.value)"
      />
      <span class="option__indicator"></span>
      {{ option.label }}
    </label>
  </div>
</template>

<script>
export default {
  props: ['value', 'label', 'options'],
  data() {
    return {
      name: `_${Math.random()}`,
    };
  },
};
</script>

<style lang="scss" scoped>
.radio-wrapper {
  margin-bottom: 25px;
}

.label {
  font-weight: 300;
  color: #242424;

  font-size: 20px;
  line-height: 1.55;

  display: block;
  height: 100%;
  padding-right: 10px;

  padding-bottom: 5px;
}

.option {
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 1.55;
  font-weight: 300;
  color: #242424;
  margin: 0 0 10px 0;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked + .option__indicator:after {
      opacity: 1;
    }
  }

  &__indicator {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    height: 20px;
    width: 20px;
    border-color: #000;
    border-style: solid;
    border-width: 2px;
    box-sizing: border-box;
    border-radius: 50%;
    margin-right: 10px;
    transition: all 0.2s;

    &:after {
      content: "";
      width: 10px;
      height: 10px;
      background: #000;
      border-radius: 50%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      opacity: 0;
      transition: all 0.2s;
      z-index: 1;
    }
  }
}
</style>
