var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "input-wrapper" },
    [
      _vm.type === "phone"
        ? _c("phone-mask-input", {
            attrs: {
              inputClass: "input",
              name: _vm.name,
              placeholder: _vm.placeholder,
              value: _vm.value,
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event)
              },
            },
          })
        : _c("input", {
            staticClass: "input",
            attrs: {
              type: _vm.nativeType,
              name: _vm.name,
              placeholder: _vm.placeholder,
            },
            domProps: { value: _vm.value },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event.target.value)
              },
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }