<template>
  <div class="input-wrapper">
    <phone-mask-input
      v-if="type === 'phone'"
      inputClass="input"
      :name="name"
      :placeholder="placeholder"
      :value="value"
      @input="$emit('input', $event)"
    ></phone-mask-input>
    <input
      v-else
      class="input"
      :type="nativeType"
      :name="name"
      :placeholder="placeholder"
      :value="value"
      @input="$emit('input', $event.target.value)"
    />
  </div>
</template>

<script>
import PhoneMaskInput from 'vue-phone-mask-input';

export default {
  props: ['placeholder', 'value', 'type', 'nativeType', 'name'],
  components: { PhoneMaskInput },
};
</script>

<style lang="scss" scoped>
.input-wrapper {
  margin-bottom: 25px;

  &::v-deep {
    .input {
      color: #000000;
      border: 1px solid #c9c9c9;
      border-radius: 0px;
      background-color: transparent;
      -webkit-appearance: none;
      margin: 0;
      font-size: 100%;
      height: 60px;
      padding: 0 20px;
      font-size: 16px;
      line-height: 1.33;
      width: 100%;
      outline: 0;

      &::placeholder {
        color: #000;
        opacity: 0.5;
      }
    }
  }
}
</style>
