<template>
  <button
    @click.prevent="onClick"
    class="button"
    :class="{ button_pending: pending }"
  >
    <div class="button__loader"></div>
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    pending: {
      type: Boolean,
    },
  },
  methods: {
    onClick($event) {
      if (this.pending) {
        return;
      }

      this.$emit('click', $event);
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin loader($size, $borderSize) {
  &,
  &:after {
    border-radius: 50%;
    width: $size;
    height: $size;
  }

  position: relative;
  border-top: $borderSize solid rgba(255, 255, 255, 0.2);
  border-right: $borderSize solid rgba(255, 255, 255, 0.2);
  border-bottom: $borderSize solid rgba(255, 255, 255, 0.2);
  border-left: $borderSize solid #ffffff;
  transform: translateZ(0);
  animation: loader 1.1s infinite linear;
}

.button {
  position: relative;
  text-align: center;
  height: 60px;
  border: 0 none;
  font-size: 16px;
  padding-left: 60px;
  padding-right: 60px;
  -webkit-appearance: none;
  font-weight: 700;
  white-space: nowrap;
  background-image: none;
  cursor: pointer;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  background: 0 0;
  color: #ffffff;
  background-color: #1620f3;
  border-radius: 30px;
  font-weight: 700;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  width: 100%;
  height: 54px;
  -webkit-appearance: none;

  &:hover {
    background-color: #000;
  }

  &_pending {
    color: transparent;
    text-indent: -9999px;
    font-size: 0;

    .button__loader {
      display: block;
    }
  }

  &__loader {
    display: none;

    @include loader(24px, 4px);

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
