var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "auth" } },
    [
      _vm.tab === "login"
        ? [
            _c("h2", { staticClass: "h2" }, [_vm._v(_vm._s(_vm.$t("login")))]),
            _vm._v(" "),
            _c("v-input", {
              attrs: { placeholder: "Email", name: "email" },
              model: {
                value: _vm.email,
                callback: function ($$v) {
                  _vm.email = $$v
                },
                expression: "email",
              },
            }),
            _vm._v(" "),
            _c("v-input", {
              attrs: {
                placeholder: _vm.$t("password"),
                name: "password",
                "native-type": "password",
              },
              model: {
                value: _vm.login.password,
                callback: function ($$v) {
                  _vm.$set(_vm.login, "password", $$v)
                },
                expression: "login.password",
              },
            }),
            _vm._v(" "),
            _c("v-error", { attrs: { text: _vm.login.error } }),
            _vm._v(" "),
            _c("v-success", { attrs: { text: _vm.login.success } }),
            _vm._v(" "),
            _c(
              "v-button",
              {
                attrs: { pending: _vm.pending },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.tryLogin.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("signIn")))]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "hint" }, [
              _vm._v("\n      " + _vm._s(_vm.$t("ifYouDontHaveAccount")) + " "),
              _c(
                "button",
                {
                  staticClass: "hint__button",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.tab = "reg"
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("toRegister")) + "\n      "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "hint" }, [
              _c(
                "button",
                {
                  staticClass: "hint__button",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.tab = "restorePassword"
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("restorePassword")) + "\n      "
                  ),
                ]
              ),
            ]),
          ]
        : _vm.tab === "confirmLogin"
        ? [
            _c("h2", { staticClass: "h2" }, [
              _vm._v(_vm._s(_vm.$t("enter2FACode"))),
            ]),
            _vm._v(" "),
            _c("v-input", {
              ref: "confirmLoginCodeInput",
              attrs: { placeholder: _vm.$t("code") },
              model: {
                value: _vm.login.code,
                callback: function ($$v) {
                  _vm.$set(_vm.login, "code", $$v)
                },
                expression: "login.code",
              },
            }),
            _vm._v(" "),
            _c("v-error", { attrs: { text: _vm.login.confirmError } }),
            _vm._v(" "),
            _c(
              "v-button",
              {
                attrs: { pending: _vm.pending },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.confirmLogin.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("send")))]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "hint" }, [
              _c(
                "button",
                {
                  staticClass: "hint__button",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.tab = "login"
                      _vm.login.confirmError = ""
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("backToLogin")) + "\n      "
                  ),
                ]
              ),
            ]),
          ]
        : _vm.tab === "reg"
        ? [
            _c("h2", { staticClass: "h2" }, [
              _vm._v(_vm._s(_vm.$t("register"))),
            ]),
            _vm._v(" "),
            _c("v-input", {
              attrs: { placeholder: "Email", name: "email" },
              model: {
                value: _vm.email,
                callback: function ($$v) {
                  _vm.email = $$v
                },
                expression: "email",
              },
            }),
            _vm._v(" "),
            _c("v-input", {
              attrs: {
                placeholder: _vm.$t("phone"),
                type: "phone",
                name: "phone",
              },
              model: {
                value: _vm.reg.phone,
                callback: function ($$v) {
                  _vm.$set(_vm.reg, "phone", $$v)
                },
                expression: "reg.phone",
              },
            }),
            _vm._v(" "),
            _c("v-radio", {
              attrs: {
                label: _vm.$t("userType"),
                options: [
                  { label: _vm.$t("personal"), value: 1 },
                  { label: _vm.$t("business"), value: 2 },
                ],
              },
              model: {
                value: _vm.reg.userType,
                callback: function ($$v) {
                  _vm.$set(_vm.reg, "userType", $$v)
                },
                expression: "reg.userType",
              },
            }),
            _vm._v(" "),
            _vm.reg.userType === 2
              ? _c("v-input", {
                  attrs: { placeholder: _vm.$t("companyName") },
                  model: {
                    value: _vm.reg.companyName,
                    callback: function ($$v) {
                      _vm.$set(_vm.reg, "companyName", $$v)
                    },
                    expression: "reg.companyName",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("v-error", { attrs: { text: _vm.reg.error } }),
            _vm._v(" "),
            _c(
              "v-button",
              { attrs: { pending: _vm.pending }, on: { click: _vm.tryReg } },
              [_vm._v(_vm._s(_vm.$t("signUp")))]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "hint" }, [
              _vm._v("\n      " + _vm._s(_vm.$t("alreadyHaveAccount")) + " "),
              _c(
                "button",
                {
                  staticClass: "hint__button",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.tab = "login"
                    },
                  },
                },
                [_vm._v("\n        " + _vm._s(_vm.$t("toLogin")) + "\n      ")]
              ),
            ]),
          ]
        : _vm.tab === "confirmReg"
        ? [
            _c("h2", { staticClass: "h2" }, [
              _vm._v(_vm._s(_vm.$t("enterConfirmationCode"))),
            ]),
            _vm._v(" "),
            _c("v-input", {
              ref: "confirmRegCodeInput",
              attrs: { placeholder: _vm.$t("code") },
              model: {
                value: _vm.reg.code,
                callback: function ($$v) {
                  _vm.$set(_vm.reg, "code", $$v)
                },
                expression: "reg.code",
              },
            }),
            _vm._v(" "),
            _c("v-error", { attrs: { text: _vm.reg.confirmError } }),
            _vm._v(" "),
            _c(
              "v-button",
              {
                attrs: { pending: _vm.pending },
                on: { click: _vm.confirmReg },
              },
              [_vm._v(_vm._s(_vm.$t("send")))]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "hint" }, [
              _c(
                "button",
                {
                  staticClass: "hint__button",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.tab = "reg"
                      _vm.reg.confirmError = ""
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("backToRegistration")) +
                      "\n      "
                  ),
                ]
              ),
            ]),
          ]
        : _vm.tab === "restorePassword"
        ? [
            _c("h2", { staticClass: "h2" }, [
              _vm._v(_vm._s(_vm.$t("restorePasswordTitle"))),
            ]),
            _vm._v(" "),
            _c("v-input", {
              attrs: { placeholder: "Email" },
              model: {
                value: _vm.restore.email,
                callback: function ($$v) {
                  _vm.$set(_vm.restore, "email", $$v)
                },
                expression: "restore.email",
              },
            }),
            _vm._v(" "),
            _c("v-error", { attrs: { text: _vm.restore.error } }),
            _vm._v(" "),
            _c(
              "v-button",
              {
                attrs: { pending: _vm.pending },
                on: { click: _vm.restorePassword },
              },
              [_vm._v(_vm._s(_vm.$t("send")))]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "hint" }, [
              _c(
                "button",
                {
                  staticClass: "hint__button",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.tab = "login"
                    },
                  },
                },
                [_vm._v("\n        Login\n      ")]
              ),
            ]),
          ]
        : _vm.tab === "restorePassword2"
        ? [
            _c("h2", { staticClass: "h2" }, [
              _vm._v(_vm._s(_vm.$t("restorePasswordTitle"))),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-text" }, [
              _vm._v(
                "\n      Request submitted, please check your email.\n    "
              ),
            ]),
          ]
        : _vm.tab === "restorePasswordCode"
        ? [
            _c("h2", { staticClass: "h2" }, [
              _vm._v(_vm._s(_vm.$t("restorePasswordTitle"))),
            ]),
            _vm._v(" "),
            _c("v-input", {
              attrs: { placeholder: _vm.$t("newPassword") },
              model: {
                value: _vm.restore.password,
                callback: function ($$v) {
                  _vm.$set(_vm.restore, "password", $$v)
                },
                expression: "restore.password",
              },
            }),
            _vm._v(" "),
            _c("v-error", { attrs: { text: _vm.restore.error } }),
            _vm._v(" "),
            _c(
              "v-button",
              {
                attrs: { pending: _vm.pending },
                on: { click: _vm.restorePasswordCode },
              },
              [_vm._v(_vm._s(_vm.$t("send")))]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "hint" }, [
              _c(
                "button",
                {
                  staticClass: "hint__button",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.tab = "login"
                    },
                  },
                },
                [_vm._v("\n        Login\n      ")]
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }